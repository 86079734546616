import { AxiosResponse } from "axios";
import externalApi from "./external-api";
import api from "./api";

const verificaAcessoGoogle = (): Promise<AxiosResponse> => {
  return externalApi.get("https://storage.googleapis.com/v360-platform-prod");
};

const verificaAcessoBeamer = (): Promise<AxiosResponse> => {
  return externalApi.get(`https://app.getbeamer.com/styles/styles.css?v=f17`);
};

const verificaAcessoApiLog = (): Promise<AxiosResponse> => {
  return externalApi.post(
    process.env.ACTION_LOG_PUB_SUB_URL ?? ""
  );
};

const verificaAcessoApiGET = (): Promise<AxiosResponse> => {
  return api.get("/diagnostico");
};
const verificaAcessoApiPOST = (): Promise<AxiosResponse> => {
  return api.post("/diagnostico");
};
const verificaAcessoApiPUT = (): Promise<AxiosResponse> => {
  return api.put("/diagnostico");
};
const verificaAcessoApiDELETE = (): Promise<AxiosResponse> => {
  return api.delete("/diagnostico");
};

const DiagnosticoService = {
  verificaAcessoGoogle,
  verificaAcessoBeamer,
  verificaAcessoApiLog,
  verificaAcessoApiGET,
  verificaAcessoApiPOST,
  verificaAcessoApiPUT,
  verificaAcessoApiDELETE,
};

export default DiagnosticoService;
